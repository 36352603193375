import React, { useState } from "react";
import styles from "./DropDown.module.scss";
import { useSelector } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import OutsideClickHandler from "react-outside-click-handler";
import { BsChevronDown } from "react-icons/bs";
const DropDown = ({ selected, color, setSelected, options, showRightIcon }) => {
  const [isActive, setIsActive] = useState(false);
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  return (
    <div className={styles.dropdown}>
      <div
        className={styles.dropdownbtn}
        onClick={(e) => setIsActive(!isActive)}
      >
        {showRightIcon === false ? null : (
          <div className={styles.imgContainer}>
            {/* <img className={styles.leftIcon} src={DollarIcon} alt="" /> */}
          </div>
        )}
        <span className={styles.selectedOptions} style={{ color: color }}>
          {metaMaskToken
            ? `${metaMaskToken}`
            : selected}
        </span>
        <BsChevronDown className={styles.withRightIcon} />
      </div>

      {isActive && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsActive(false);
          }}
        >
          <div className={styles.dropdowncontent}>
            {options?.map((option) => (
              <div
                onClick={(e) => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={styles.dropdownitems}
              >
                {option}
                <FiLogOut className="min-w-8 min-h-8 text-xl" />
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default DropDown;
