import Web3 from "web3";
// import STACKING_ABI from "../ABI/stacking_ABI.json";
import USDT_ABI from "../ABI/USDT_ABI.json";
import USDC_ABI from "../ABI/USDC_ABI.json";
import BCON_ABI from "../ABI/BCON_ABI,.json";
import { BCON_CONTRACT_ADDRESS, GetProvider, USDC_CONTRACT_ADDRESS, USDT_CONTRACT_ADDRESS } from "../constans/appconstans";



export const GetUsdtContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(USDT_ABI, USDT_CONTRACT_ADDRESS);
      return MyContract;
    } else return null;
  } catch (error) { }
};


export const GetUsdcContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(USDC_ABI, USDC_CONTRACT_ADDRESS);
      return MyContract;
    } else return null;
  } catch (error) { }
};


export const GetBconContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(BCON_ABI, BCON_CONTRACT_ADDRESS);
      return MyContract;
    } else return null;
  } catch (error) { }
};
