import WalletConnectProvider from "@walletconnect/web3-provider";
import { walletConnectModal } from "../helper/web3ModalConnector";

export const ChainID = 56;
// export const ChainID = 97;
export const RPC_URL = "https://bsc-dataseed.binance.org/";
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";


export const profitWalletAddress = "0x3c81F4069b79B05F1245D4d530B530FC89154e80";



export const USDT_CONTRACT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";
export const USDC_CONTRACT_ADDRESS = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
export const BCON_CONTRACT_ADDRESS = "0x2170ed0880ac9a755fd29b2688956bd959f933f8";


export const blockExplorerUrls = "https://bscscan.com/";
// export const blockExplorerUrls = "https://testnet.bscscan.com/";

export async function GetProvider() {
  // const { account } = useActiveWeb3React();
  // const web3 = new Web3();
  // web3.setProvider(window.web3.currentProvider);
  const web3 = await walletConnectModal();
  return web3;
}
export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: `${RPC_URL}`,
        // 97: `${RPC_URL}`,
      },
      chainId: ChainID,
    },
  },
};

export const BNB_NETWORK = {
  chainId: "0x61",
};
