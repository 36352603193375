import React, { lazy, Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import MobileUIWalletModal from "./Components/__common/Mobile-UI-Wallet";
import ConnectWallet from "./Components/ConnectWallet";
const HomePage = lazy(() => import("./Components/Home"));

const App = () => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const useMobileWallet = useSelector((state) => state.token.useMobileWallet);
  console.error = function () {};

  return (
    <>
      <Suspense>
        <HelmetProvider>
          <Routes>
            {/* <Route path="/" element={<HomePage />} /> */}
            <Route path="/" element={<ConnectWallet />} />
          </Routes>
        </HelmetProvider>
      </Suspense>
      {useMobileWallet && !metaMaskToken ? <MobileUIWalletModal /> : null}
    </>
  );
};
export default App;
