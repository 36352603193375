import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React as useWeb3ReactCore } from "@web3-react/core";
import Web3Modal from "web3modal";
import Web3 from "web3";
// import { GetProvider } from "../contract/nftBalanceContract";
import { GetProvider, profitWalletAddress, providerOptions } from "../constans/appconstans"
import { GetBconContract, GetUsdcContract, GetUsdtContract } from "../contract/nftBalanceContract";

export const injected = new InjectedConnector({
  supportedChainIds: [97, 43113, 56],
});


export function useActiveWeb3React() {
  const context = useWeb3ReactCore();
  const contextNetwork = useWeb3ReactCore("Network");
  return context.active ? context : contextNetwork;
}
export const walletconnect = new WalletConnectConnector({
  infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
  qrcode: true,
});
export function resetWalletConnector(connector) {
  if (connector && connector instanceof WalletConnectConnector) {
    connector.walletConnectProvider = undefined;
  }
}

//--------For BNB Transaction-----// 
export const payBNB = async (fromTransfer, total) => {
  console.log("total::::", total)
  let convertAmount = Web3.utils.toWei((total).toString(), "ether")
  let web3 = await GetProvider();
  let gasPrice = await web3.eth.getGasPrice();
  let gasLimit = 21000;
  let gasPriceBN = web3.utils.toBN(gasPrice);
  let gasFeeWei = gasPriceBN.mul(web3.utils.toBN(gasLimit));

  // Convert gas fee to BNB
  let gasFeeBNB = web3.utils.fromWei(gasFeeWei.toString(), 'ether');

  // Convert sending amount to wei
  let sendingAmountWei = web3.utils.toWei(total, 'ether');

  // Adjust sending amount by subtracting gas fee
  let sendingAmountWeiBN = web3.utils.toBN(sendingAmountWei);
  let adjustedAmountWeiBN = sendingAmountWeiBN.sub(gasFeeWei);

  // Convert adjusted amount back to BNB
  let adjustedAmountBNB = web3.utils.fromWei(adjustedAmountWeiBN.toString(), 'ether');

  console.log(`Gas Fee in BNB: ${gasFeeBNB}`);
  console.log(`Adjusted Sending Amount in BNB: ${adjustedAmountBNB}`);
  try {

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });
    const provider = await web3Modal.connect();
    await web3Modal.toggleModal();
    // regular web3 provider methods
    const newWeb3 = new Web3(provider);
    const params = {
      from: fromTransfer,
      to: profitWalletAddress,
      value: web3.utils.toWei(adjustedAmountBNB, 'ether'), 
      // gasLimit: gasLimit,
      gasPrice,
    };

    const sendHash = await newWeb3.eth.sendTransaction(params);
    return sendHash;
  } catch (e) {
    console.log("::::;", e)
    return e;
  }
};

export const checkUSDTBalance = async (fromTransfer) => {
  console.log("fromTransfer::::;", fromTransfer)
try {
  const contract =  await GetUsdtContract()
  const response = await contract.methods.balanceOf(fromTransfer).call()
  const readableBalance = response / Math.pow(10, 6);
    return readableBalance;
} catch (error) {
  
}
}

export const transferUSDT = async (amount, fromAddress) => {
  try {
    const contract =  await GetUsdtContract()
    const amountInSmallestUnit = amount * Math.pow(10, 6);
    const response = await contract.methods.transfer(profitWalletAddress, amountInSmallestUnit.toString()).send({ from: fromAddress });
    console.log("Transfer response:", response);
    return response;
  } catch (error) {
    
  }
}


export const checkUSDCBalance = async (fromTransfer) => {
  try {
    const contract =  await GetUsdcContract()
    const response = await contract.methods.balanceOf(fromTransfer).call()
    // const readableBalance = response / Math.pow(10, 6);
    console.log("readableBalance::::", response)

    return response;
  } catch (error) {
    console.log("error::::;", error)
  }
  }

  export const transferUSDC = async (amount, fromAddress) => {
    try {
      const contract =  await GetUsdcContract()
      const response = await contract.methods.transfer(profitWalletAddress, amount).send({ from: fromAddress });
      console.log("Transfer response:", response);
      return response;
    } catch (error) {
      
    }
  }

  export const checkbconBalance = async (metaMaskToken) => {
    try {
      const contract =  await GetBconContract()
      const response = await contract.methods.balanceOf(metaMaskToken).call()
      const readableBalance = response / Math.pow(10, 6);
      return readableBalance;
    } catch (error) {
    }
    
  }

  export const transferBCON = async (amount, fromAddress) => {
    try {
      const contract =  await GetBconContract()
    const amountInSmallestUnit = amount * Math.pow(10, 6);

    const response = await contract.methods.transfer(profitWalletAddress, amountInSmallestUnit.toString()).send({ from: fromAddress });
    console.log("Transfer response:", response);
    return response;
    } catch (error) {
      
    }
  }


//--------For Coin Voting-----// 
// export const payVoteNew = async (fromTransfer, vote) => {
//   let ConverToNumber = Number(vote)
//   let convertAmount = Web3.utils.toWei(ConverToNumber.toString(), "ether")
//   let web3 = await GetProvider();
//   let gasPrice = await web3.eth.getGasPrice();
//   let gasLimit = 21000;

//   try {
//     const contract = await GetVoteTokenContract();

//     gasLimit = await contract.methods.transfer(
//       ProjectWalletAddress, convertAmount
//     ).estimateGas({
//       from: fromTransfer
//     });

//     const Response = await contract.methods.transfer(
//       ProjectWalletAddress, convertAmount
//     ).send({
//       from: fromTransfer, gasLimit: gasLimit,
//       gasPrice,
//     });
//     return Response;
//   } catch (e) {
//     return e;
//   }
// };


//--------For ZAH Voting-----//
// export const payZahNew = async (fromTransfer, vote) => {

//   let convertAmount = Web3.utils.toWei(Number(vote).toString(), "ether")
//   try {
//     const contract = await GetZahTokenContract();
//     await contract.methods.transfer(
//       ProjectWalletAddress, convertAmount
//     )
//       .send({ from: fromTransfer });

//   } catch (e) {
//     return e;
//   }
// };


//--------Only Declare Not used in Any Component old-----//
// export const payVoteToken = async (param) => {
//   try {
//     const web3 = new Web3(window.ethereum);
//     const sendHash = await web3.eth.sendTransaction(param);
//     return sendHash;
//   } catch (e) {
//     return e;
//   }
// };



